import filter from 'lodash/fp/filter';
import join from 'lodash/fp/join';
import pipe from 'lodash/fp/pipe';
import uniq from 'lodash/fp/uniq';
import { convertISOStrToDOBStr } from '@hotelplan/components.common.dob-input';
import { ITravelRoomsState } from '@hotelplan/components.common.travel-rooms';
import {
  mapSearchControlTravelPeriodToFormTravelDates,
  mergeFdrTravelDatesState,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { TFdrFlatDestination } from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import { FdrForceSrlSingleView } from '@hotelplan/supergraph-api';
import { normalizeFilters } from 'fdr/components/candidate/fdr-search-filters-controls/use-fdr-filter-render.utils';
import {
  fdrMapFilters,
  getOptionToFilterMap,
} from 'fdr/components/candidate/fdr-search/mappers/fdr-filter.mapper';
import { getFdrControlsDestinations } from 'fdr/components/candidate/fdr-search/mappers/fdr-srl-controls-response.mapper';
import {
  IFdrProductSrlControlState,
  IFdrSRLState,
  TFdrProductSrlControlStateWithType,
} from 'fdr/components/candidate/fdr-search/types/search-form.types';
import { FdrSearchControlsResponseFragment } from 'fdr/schemas/fragment/search-controls/fdr-search-controls-response.generated';
import { FdrSearchControlsFragment } from 'fdr/schemas/fragment/search-controls/fdr-search-controls.generated';
import { FdrPdpControlsTravelPeriodQuery } from 'fdr/schemas/query/pdp/search-controls/fdr-pdp-controls-travel-period.generated';
import { FdrPdpSearchControlsQuery } from 'fdr/schemas/query/pdp/search-controls/fdr-pdp-search-controls.generated';
import { FdrAutocompleteItemFragment } from 'fdr/schemas/query/search/autocomplete/fdr-text-search-autocomplete.generated';
import { FdrControlsTravelPeriodQuery } from 'fdr/schemas/query/search/search-controls/fdr-controls-travel-period.generated';
import { FdrSearchControlsQuery } from 'fdr/schemas/query/search/search-controls/fdr-search-controls.generated';
import { FdrWishlistControlsQuery } from 'fdr/schemas/query/wishlist/search-controls/fdr-wishlist-controls.generated';

export const concatDestinationSections = pipe(
  filter(Boolean),
  uniq,
  join(', ')
);

type TFdrDestination =
  FdrSearchControlsFragment['travelDestinations']['destinations'][number];

export function getCanonicalNameAndMythosCode(item) {
  let mythosCode = null;
  let canonicalName = item.name;

  switch (item.__typename) {
    case 'FdrRegion':
    case 'FdrDestination':
      canonicalName = concatDestinationSections([
        item.name,
        item?.country?.name,
      ]);
      break;

    case 'FdrResort':
      canonicalName = concatDestinationSections([
        item.name,
        item?.destination?.name,
        item?.destination?.country?.name,
      ]);
      break;

    case 'FdrHotel':
      mythosCode = item.mythosCode;
      canonicalName = concatDestinationSections([
        item.name,
        item?.resort?.name,
        item?.resort?.destination?.country?.name,
      ]);
      break;
  }

  return { mythosCode, canonicalName };
}

const getDestinationItem = (destination: TFdrDestination) => {
  switch (destination.__typename) {
    case 'FdrSearchControlsAirportDestination':
      return destination.airport;

    case 'FdrSearchControlsGeoDestination':
      return destination.geo;

    case 'FdrSearchControlsProductDestination':
      return destination.product;

    case 'FdrSearchControlsThemeDestination':
      return destination.theme;

    default:
      return null;
  }
};

export function flattenFdrDestination(
  destination: TFdrDestination
): TFdrFlatDestination {
  const item = getDestinationItem(destination);

  if (!item) return null;

  const { id, name, __typename } = item;
  const { mythosCode, canonicalName } = getCanonicalNameAndMythosCode(item);

  return { id, name, mythosCode, canonicalName, __typename };
}

const getAutocompleteItem = (destination: FdrAutocompleteItemFragment) => {
  switch (destination.__typename) {
    case 'FdrAdventureTravelSearchItem':
      return destination.adventureTravel;

    case 'FdrContinentSearchItem':
      return destination.continent;

    case 'FdrAirportSearchItem':
      return destination.airport;

    case 'FdrCountryGroupSearchItem':
      return destination.countryGroup;

    case 'FdrCountrySearchItem':
      return destination.country;

    case 'FdrCruiseSearchItem':
      return destination.cruise;

    case 'FdrDestinationSearchItem':
      return destination.destination;

    case 'FdrHotelSearchItem':
      return destination.hotel;

    case 'FdrRegionSearchItem':
      return destination.region;

    case 'FdrResortSearchItem':
      return destination.resort;

    case 'FdrRoundTripSearchItem':
      return destination.roundTrip;

    case 'FdrThemeSearchItem':
      return destination.theme;

    default:
      return null;
  }
};

export function flattenFdrAutocomplete(
  destination: FdrAutocompleteItemFragment
): TFdrFlatDestination {
  const item = getAutocompleteItem(destination);

  if (!item) return null;

  const { id, name, __typename } = item;
  const { mythosCode, canonicalName } = getCanonicalNameAndMythosCode(item);

  return { id, name, mythosCode, canonicalName, __typename };
}

export const getFdrControlsTravelRooms = ({
  rooms,
  travellers: { roomDistribution, adults, childrenDobs },
}: FdrSearchControlsFragment): ITravelRoomsState => {
  return {
    adults: adults || 0,
    childrenDobs: (childrenDobs || []).map(convertISOStrToDOBStr),
    rooms: rooms || 0,
    travellersDistribution: roomDistribution
      ? {
          rooms: roomDistribution.map(room => ({
            adultsAmount: room.adults,
            childrenIdx:
              childrenDobs && room?.childrenDobs
                ? room.childrenDobs
                    .map(dob => childrenDobs.indexOf(dob) + 1)
                    .filter(idx => idx > 0)
                : [],
          })),
        }
      : null,
  };
};

// mapSRLSearchControlValuesToFormState
export const fdrMapSRLSearchControlValuesToFormState = (
  searchControl: FdrSearchControlsFragment | undefined
): TFdrProductSrlControlStateWithType => {
  if (!searchControl) return null;

  const legacy_travelDestination = {
    destinations: searchControl?.travelDestinations?.destinations?.map(
      flattenFdrDestination
    ),
    ids: getFdrControlsDestinations(searchControl.travelDestinations),
  };

  return {
    type: 'HOTEL',
    extended_travelType: {
      fdr: searchControl.travelType,
    },
    travelDates: mapSearchControlTravelPeriodToFormTravelDates(
      searchControl.travelPeriod
    ),
    travelRooms: getFdrControlsTravelRooms(searchControl),
    legacy_travelDestination,
  };
};

// mapSRLSearchValuesQueryToSRLState
export const fdrMapSRLSearchValuesQueryToSRLState = (
  controls: FdrSearchControlsFragment,
  forceSrlSingleView?: FdrForceSrlSingleView
): IFdrSRLState => {
  return {
    searchControl: fdrMapSRLSearchControlValuesToFormState(controls),
    subGeoFilters: controls.travelDestinations?.selectedSubGeo,
    primarySort: undefined,
    productSorting: controls.sorting.productSorting,
    groupSorting: controls.sorting.groupSorting,
    forceSingleView: forceSrlSingleView?.value,
    // @todo 4 ales: optimize iterations
    normalizedFilters: normalizeFilters(controls.filters),
    optionToFilterMap: getOptionToFilterMap(controls.filters),
    filters: fdrMapFilters(controls.filters),
  };
};

// mergeHotelSearchControlState
export function fdrMergeHotelSearchControlState(
  searchControlState?: IFdrProductSrlControlState | null,
  globalSearchState?: IFdrProductSrlControlState | null
): TFdrProductSrlControlStateWithType | null {
  if (!searchControlState) return null;

  return {
    type: 'HOTEL',
    ...searchControlState,
    ...globalSearchState,
    travelDates: mergeFdrTravelDatesState(
      searchControlState?.travelDates,
      globalSearchState?.travelDates
    ),
  };
}

type T_Expected_SearchControlsQueryType =
  | FdrSearchControlsQuery
  | FdrWishlistControlsQuery
  | FdrPdpSearchControlsQuery;

export const getControlsMappedData = (
  data: T_Expected_SearchControlsQueryType
) => {
  if (!data) return null;
  let source = null;

  if ('fdrProductSearchResultListPage' in data) {
    source = data.fdrProductSearchResultListPage;
  }

  if ('fdrProduct' in data) {
    source = data.fdrProduct;
  }

  if ('fdrWishlistPage' in data) {
    source = data.fdrWishlistPage;
  }

  if (!source?.searchControls?.controls) return null;

  const controls = source?.searchControls.controls;

  return fdrMapSRLSearchValuesQueryToSRLState(
    controls,
    'forceSrlSingleView' in source ? source?.forceSrlSingleView : null
  );
};

export const fdrMapResponseSearchControlsToState = (
  srlData: T_Expected_SearchControlsQueryType,
  defaultValues?: T_Expected_SearchControlsQueryType
): IFdrSRLState => {
  const mappedState = getControlsMappedData(srlData);
  const defaultState = getControlsMappedData(defaultValues);

  const travelDatesDefaults =
    defaultState?.searchControl?.travelDates?.defaults;

  const searchControl = fdrMergeHotelSearchControlState(
    mappedState?.searchControl
  );

  // Attach defaults dates from search control default data
  if (searchControl?.travelDates && travelDatesDefaults) {
    searchControl.travelDates.defaults = travelDatesDefaults;
  }

  return { ...mappedState, searchControl };
};

const getSearchControls = (
  data:
    | FdrPdpControlsTravelPeriodQuery
    | FdrControlsTravelPeriodQuery
    | T_Expected_SearchControlsQueryType
) => {
  if (!data) return null;

  if ('fdrProduct' in data && 'searchControls' in data.fdrProduct)
    return data.fdrProduct.searchControls;

  if ('fdrProductSearchResultListPage' in data)
    return data.fdrProductSearchResultListPage.searchControls;

  return null;
};

const getTravelPeriod = (
  periodData: FdrPdpControlsTravelPeriodQuery | FdrControlsTravelPeriodQuery
) => {
  const searchControls = getSearchControls(periodData);

  if (!searchControls) return null;
  return searchControls?.controls?.travelPeriod;
};

const fdrSetTravelPeriodInControls = (
  searchControls:
    | FdrSearchControlsResponseFragment
    | FdrControlsTravelPeriodQuery['fdrProductSearchResultListPage']['searchControls'],
  travelPeriod: FdrSearchControlsResponseFragment['controls']['travelPeriod']
) => {
  if (!searchControls || !searchControls?.controls) return null;

  return {
    ...searchControls,
    controls: { ...searchControls.controls, travelPeriod },
  };
};

export const fdrSetTravelPeriodInData = (
  data: T_Expected_SearchControlsQueryType,
  periodData: FdrPdpControlsTravelPeriodQuery | FdrControlsTravelPeriodQuery
) => {
  const travelPeriod = getTravelPeriod(periodData);
  const prevSearchControls = getSearchControls(data);

  if (!travelPeriod || !prevSearchControls) return null;

  const searchControls = fdrSetTravelPeriodInControls(
    prevSearchControls,
    travelPeriod
  );

  if ('fdrProduct' in data && data.fdrProduct) {
    return {
      ...data,
      fdrProduct: { ...data.fdrProduct, searchControls },
    };
  }

  if (
    'fdrProductSearchResultListPage' in data &&
    data.fdrProductSearchResultListPage
  ) {
    return {
      ...data,
      fdrProductSearchResultListPage: {
        ...data.fdrProductSearchResultListPage,
        searchControls,
      },
    };
  }

  return null;
};
