import { FdrTravelDestinationsCriteriaInput } from '@hotelplan/supergraph-api';
import {
  FdrSearchControlAirportDestinationsFragment,
  FdrSearchControlGeoDestinationsFragment,
  FdrSearchControlProductDestinationsFragment,
  FdrSearchControlThemeDestinationsFragment,
} from 'fdr/schemas/fragment/destinations/fdr-travel-destinations.generated';
import { FdrSearchControlsFragment } from 'fdr/schemas/fragment/search-controls/fdr-search-controls.generated';

type UDestination =
  | FdrSearchControlAirportDestinationsFragment
  | FdrSearchControlGeoDestinationsFragment
  | FdrSearchControlProductDestinationsFragment
  | FdrSearchControlThemeDestinationsFragment;

const isAirportDestination = (
  item: UDestination
): item is FdrSearchControlAirportDestinationsFragment =>
  item.__typename === 'FdrSearchControlsAirportDestination';

const isGeoDestination = (
  item: UDestination
): item is FdrSearchControlGeoDestinationsFragment =>
  item.__typename === 'FdrSearchControlsGeoDestination';

const isProductDestination = (
  item: UDestination
): item is FdrSearchControlProductDestinationsFragment =>
  item.__typename === 'FdrSearchControlsProductDestination';

const isThemeDestination = (
  item: UDestination
): item is FdrSearchControlThemeDestinationsFragment =>
  item.__typename === 'FdrSearchControlsThemeDestination';

export const getFdrControlsDestinations = (
  travelDestinations: FdrSearchControlsFragment['travelDestinations']
): FdrTravelDestinationsCriteriaInput => {
  return {
    arrivalAirportIatas: travelDestinations?.destinations
      ?.filter(isAirportDestination)
      .map(({ airport }) => airport?.iata),
    geoPublicIds: travelDestinations?.destinations
      ?.filter(isGeoDestination)
      .map(({ geo }) => geo?.id)
      .filter(Boolean),
    productPublicIds: travelDestinations?.destinations
      ?.filter(isProductDestination)
      .map(({ product }) => product?.id),
    themePublicIds: travelDestinations?.destinations
      ?.filter(isThemeDestination)
      .map(({ theme }) => theme?.id),
  };
};
