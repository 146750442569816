import { IFiltersTrackableData } from '@hotelplan/libs.tracking/trackingEvents/trackSearch';
import { normalizeOption } from 'fdr/components/candidate/fdr-search-filters-controls/use-fdr-filter-render.utils';
import {
  EFilterIds,
  IFdrFiltersFormState,
  T_Union_FilterOption,
} from 'fdr/components/candidate/fdr-search/types/filters.types';
import {
  FdrFiltersFragment,
  FdrFlightFiltersFragment,
} from 'fdr/schemas/fragment/filters/fdr-filters.generated';
import { filterEmptyCriteria } from './fdr-srl-form-to-criteria-input.mapper';

const SINGLE_SELECT_FILTER_TYPES = [
  `FdrSearchControlsSingleSelectFilter`,
  `FdrFlightSearchControlsSingleSelectFilter`,
];

export const fdrMapFilters = (
  filters: Array<FdrFiltersFragment | FdrFlightFiltersFragment>
): IFdrFiltersFormState => {
  if (!filters) return {};

  return filters.reduce((acc, f) => {
    if (f.id === EFilterIds.MOST_POPULAR) return acc;

    if ('options' in f) return addMultipleOptions(acc, f);

    if ('option' in f && f.option.selected) {
      const selected = normalizeOption(f.option);

      return {
        ...acc,
        [f.id]: { updated: selected, value: selected?.beValue },
      };
    }

    return acc;
  }, {});
};

function addMultipleOptions(
  acc: IFdrFiltersFormState,
  {
    options,
    id,
    __typename,
  }: {
    options: T_Union_FilterOption[];
    id: string;
    __typename: string;
  }
): IFdrFiltersFormState {
  const normalizedOptions = options.map(normalizeOption);

  if (id === EFilterIds.PRICE && normalizedOptions?.length) {
    return {
      ...acc,
      [id]: {
        updated: normalizedOptions,
        value: {
          min: Number(normalizedOptions[0]?.beValue),
          max: Number(normalizedOptions[normalizedOptions.length - 1]?.beValue),
        },
      },
    };
  }

  const selected = normalizedOptions?.filter(o => o.selected);

  if (!selected || !selected.length) return acc;

  if (SINGLE_SELECT_FILTER_TYPES.includes(__typename)) {
    return {
      ...acc,
      [id]: { updated: selected[0], value: selected[0].beValue },
    };
  }

  return {
    ...acc,
    [id]: { updated: selected, value: selected.map(o => o.beValue) },
  };
}

export function getOptionToFilterMap(
  filters: Array<FdrFiltersFragment | FdrFlightFiltersFragment>
): Record<string, EFilterIds> {
  if (!filters) return {};

  return Object.fromEntries(
    filters.reduce((acc, filter): Array<[string, EFilterIds]> => {
      if (filter.id === EFilterIds.MOST_POPULAR) return acc;

      if ('options' in filter) {
        filter.options.forEach(option => {
          acc.push([`${option.__typename}_${option.id}`, filter.id]);
        });

        return acc;
      }

      if ('option' in filter && filter?.option) {
        return [
          ...acc,
          [`${filter.option.__typename}_${filter.option.id}`, filter.id],
        ];
      }

      return acc;
    }, [])
  );
}

const mapAttributes = (
  filters: IFdrFiltersFormState
): IFiltersTrackableData['attributes'] => ({
  keys: filters[EFilterIds.MAIN_FEATURES]?.value?.length
    ? filters[EFilterIds.MAIN_FEATURES].value
    : null,
});

const mapFlightOptions = (
  filters: IFdrFiltersFormState
): IFiltersTrackableData['flightOptions'] => ({
  departure: filters[EFilterIds.DEPARTURE_FLIGHT_TIME]?.value?.max ?? undefined, // todo: check what exact value should be here (.max probably wrong)
  arrival: filters[EFilterIds.RETURN_FLIGHT_TIME]?.value?.max ?? undefined, // todo: check what exact value should be here (.max probably wrong)
  stopOvers: filters[EFilterIds.MAX_STOPS]?.value?.toString() ?? undefined,
  airLines: filters[EFilterIds.AIRLINES]?.value ?? undefined,
  stopOverTime: filters[EFilterIds.FLIGHT_STOPOVER_DURATION]?.value
    ? {
        min: filters[
          EFilterIds.FLIGHT_STOPOVER_DURATION
        ]?.value.selected.minMinutes.toString(),
        max: filters[
          EFilterIds.FLIGHT_STOPOVER_DURATION
        ]?.value.selected.maxMinutes.toString(),
      }
    : undefined,
});

const mapRatings = (
  filters: IFdrFiltersFormState
): Pick<IFiltersTrackableData, 'hotelPlanRating' | 'tripAdvisorRating'> => ({
  hotelPlanRating:
    filters[EFilterIds.HP_RATINGS]?.value?.toString() ?? undefined,
  tripAdvisorRating:
    filters[EFilterIds.TA_RATINGS]?.value?.toString() ?? undefined,
});

const mapPrice = (
  filters: IFdrFiltersFormState
): { price: IFiltersTrackableData['price'] } => ({
  price: {
    max: filters[EFilterIds.PRICE]?.value?.max?.toString() ?? undefined,
  },
});

const mapRoom = (
  filters: IFdrFiltersFormState
): IFiltersTrackableData['room'] => ({
  boards: filters[EFilterIds.MEAL_TYPES]?.value?.length
    ? filters[EFilterIds.MEAL_TYPES].value
    : null,
  types: filters[EFilterIds.ROOM_TYPES]?.value?.length
    ? filters[EFilterIds.ROOM_TYPES].value
    : null,
});

export function mapFdrFiltersStateToTrackableData(
  filters: IFdrFiltersFormState
): IFiltersTrackableData {
  if (!filters) return null;

  const mappedData = {
    attributes: mapAttributes(filters),
    flightOptions: mapFlightOptions(filters),
    ...mapRatings(filters),
    price: mapPrice(filters),
    room: mapRoom(filters),
  };

  return filterEmptyCriteria(mappedData);
}
