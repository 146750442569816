import { mapTravelDatesModalToTrackableTravelDates } from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { TravelDatesPayload } from '@hotelplan/libs.tracking';
import { FdrFlightPartition } from 'fdr/components/local/fdr-flight-search/fdr-flight-search.types';

export function mapFdrFlightTravelDatesModalToTrackableTravelDates(
  flightPartitions: FdrFlightPartition[]
): TravelDatesPayload | undefined {
  if (!flightPartitions.length) return undefined;

  if (flightPartitions.length === 1) {
    return flightPartitions[0].travelDates
      ? mapTravelDatesModalToTrackableTravelDates(
          flightPartitions[0].travelDates
        )
      : undefined;
  }

  return {
    from: flightPartitions[0].travelDates?.departureDate || '',
    to: flightPartitions[1].travelDates?.departureDate || '',
  };
}
