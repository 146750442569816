import {
  EFilterIds,
  ENormalizedFilterType,
  T_Union_FilterOption,
  TBeAcceptableValues,
  TGroupedFilter,
  TNormalizedFilter,
  TNormalizedMultiSelectFilter,
  TNormalizedOneOptionFilter,
  TNormalizedOption,
  TNormalizedSingleSelectFilter,
  TStopOverDurationValue,
} from 'fdr/components/candidate/fdr-search/types/filters.types';
import {
  FdrDepartureFlightArrivalTimeWithCountsFragment,
  FdrDepartureFlightDepartureTimeWithCountsFragment,
  FdrHpRatingOptionWithCountsFragment,
  FdrReturnFlightArrivalTimeWithCountsFragment,
  FdrReturnFlightDepartureTimeWithCountsFragment,
  FdrTaRatingOptionWithCountsFragment,
} from 'fdr/schemas/fragment/filters/fdr-filters-with-count.generated';
import {
  FdrDepartureFlightArrivalTimeFragment,
  FdrDepartureFlightDepartureTimeFragment,
  FdrFiltersFragment,
  FdrFlightFilterOptionFdrSearchControlsFlightStopoverDurationOptionFragment,
  FdrFlightFiltersFragment,
  FdrFlightMultiSelectFilterFragment,
  FdrFlightOneOptionFilterFragment,
  FdrFlightSingleSelectFilterFragment,
  FdrHpRatingOptionFragment,
  FdrMultiSelectFilterFragment,
  FdrOneOptionFilterFragment,
  FdrReturnFlightArrivalTimeFragment,
  FdrReturnFlightDepartureTimeFragment,
  FdrSingleSelectFilterFragment,
  FdrTaRatingOptionFragment,
} from 'fdr/schemas/fragment/filters/fdr-filters.generated';

function getOptionLabel(option: T_Union_FilterOption) {
  if (option.__typename === 'FdrSearchControlsAirlineOption')
    return option.airline.name;

  return 'caption' in option ? option.caption : '';
}

const TIME_ARRIVAL_DEPARTURE_FILTER_TYPES = [
  `FdrSearchControlsDepartureFlightDepartureTimeOption`,
  `FdrSearchControlsReturnFlightArrivalTimeOption`,
  `FdrSearchControlsReturnFlightDepartureTimeOption`,
  `FdrSearchControlsDepartureFlightArrivalTimeOption`,
];

function isArrivalDepartureTimeOption(
  option: T_Union_FilterOption
): option is
  | FdrReturnFlightDepartureTimeWithCountsFragment
  | FdrReturnFlightDepartureTimeFragment
  | FdrDepartureFlightDepartureTimeWithCountsFragment
  | FdrDepartureFlightDepartureTimeFragment
  | FdrDepartureFlightArrivalTimeWithCountsFragment
  | FdrDepartureFlightArrivalTimeFragment
  | FdrReturnFlightArrivalTimeWithCountsFragment
  | FdrReturnFlightArrivalTimeFragment {
  return TIME_ARRIVAL_DEPARTURE_FILTER_TYPES.includes(option.__typename);
}

function isRatingOption(
  option: T_Union_FilterOption
): option is
  | FdrHpRatingOptionWithCountsFragment
  | FdrHpRatingOptionFragment
  | FdrTaRatingOptionWithCountsFragment
  | FdrTaRatingOptionFragment {
  return (
    option.__typename === 'FdrSearchControlsHpRatingOption' ||
    option.__typename === 'FdrSearchControlsTaRatingOption'
  );
}

function getStopOverDurationValue(
  option: FdrFlightFilterOptionFdrSearchControlsFlightStopoverDurationOptionFragment
): TStopOverDurationValue {
  const { minDuration: minAvailable, maxDuration: maxAvailable } =
    option?.availableRange || {};
  const { minDuration: minSelected, maxDuration: maxSelected } =
    option?.selectedRange || {};

  return {
    available: { minMinutes: minAvailable, maxMinutes: maxAvailable },
    selected: { maxMinutes: maxSelected, minMinutes: minSelected },
  };
}

export function getOptionBeValue(option: T_Union_FilterOption) {
  // @todo 4 Dmitry: this mapping might be useful as we map here options to values that BE expects based on option type
  if (isRatingOption(option)) {
    return option.rating;
  }

  if (option.__typename === 'FdrSearchControlsFlightStopoverDurationOption') {
    return getStopOverDurationValue(option);
  }

  if (isArrivalDepartureTimeOption(option)) {
    return option.from && option.to
      ? { min: option.from, max: option.to }
      : undefined;
  }

  if (option.__typename === 'FdrSearchControlsMaxPriceOption')
    return option.maxPrice;

  if (option.__typename === 'FdrSearchControlsFlightDurationOption')
    return option.maxDuration;

  if (option.__typename === 'FdrSearchControlsMaxStopsOption')
    return option.value;

  if (option.__typename === 'FdrSearchControlsMinPriceOption')
    return option.minPrice;

  return 'id' in option ? option.id : '';
}

const NO_COUNT_OPTION_TYPENAMES = [
  `FdrSearchControlsMaxPriceOption`,
  `FdrSearchControlsFlightStopoverDurationOption`,
];

export function normalizeOption(
  option: T_Union_FilterOption
): TNormalizedOption | null {
  if (
    option.__typename === `FdrSearchControlsProductProviderOption` ||
    option.__typename === `FdrSearchControlsProductCodeOption` ||
    option.__typename === `FdrSearchControlsFlightProviderOption`
  )
    return null;

  const count = 'productCount' in option ? option['productCount'] : 0;

  return {
    id: option.id,
    __typename: option.__typename,
    label: getOptionLabel(option),
    beValue: getOptionBeValue(option),
    count,
    disabled: !NO_COUNT_OPTION_TYPENAMES.includes(option.__typename) && !count,
    visible: option.visible,
    selected: option.selected,
    isMissingCount: !('productCount' in option),
    isMissingCaption: !('caption' in option),
    __tmp_rawOption: option, //@todo_remove
  };
}

const stringifyBeValue = (beValue: TBeAcceptableValues) => {
  if (beValue && typeof beValue === 'object') {
    if ('available' in beValue && 'selected' in beValue) {
      // Handle TStopOverDurationValue
      const { available, selected } = beValue;
      return `${available.minMinutes}-${available.maxMinutes}-${selected.minMinutes}-${selected.maxMinutes}`;
    }
    // Handle price range
    if ('min' in beValue && 'max' in beValue) {
      return `${beValue.min}-${beValue.max}`;
    }
  }

  return beValue;
};

export const genHash = (id: string, options: TNormalizedOption[]) => {
  return [
    id,
    ...(options || []).flatMap(o => [
      o.id,
      stringifyBeValue(o.beValue),
      o.count,
      o.selected,
    ]),
  ].join('-');
};

function getSingleFilter(
  filter: FdrSingleSelectFilterFragment | FdrFlightSingleSelectFilterFragment
): TNormalizedSingleSelectFilter {
  const options = filter.options.map(normalizeOption);
  const hash = genHash(filter.id, options);

  return {
    id: filter.id as EFilterIds,
    name: filter.name,
    visible: filter.visible,
    type: ENormalizedFilterType.SINGLE_SELECT,
    options,
    __hash: hash,
  };
}

function getMultiFilter(
  filter: FdrMultiSelectFilterFragment | FdrFlightMultiSelectFilterFragment
): TNormalizedMultiSelectFilter {
  const options = filter.options.map(normalizeOption);
  const hash = genHash(filter.id, options);

  return {
    id: filter.id as EFilterIds,
    name: filter.name,
    visible: filter.visible,
    type: ENormalizedFilterType.MULTI_SELECT,
    options,
    __hash: hash,
  };
}

function getOneOptionFilter(
  filter: FdrOneOptionFilterFragment | FdrFlightOneOptionFilterFragment
): TNormalizedOneOptionFilter {
  const option = normalizeOption(filter.option);
  const hash = genHash(filter.id, [option]);

  return {
    id: filter.id as EFilterIds,
    name: filter.name,
    visible: filter.visible,
    type: ENormalizedFilterType.ONE_OPTIONAL,
    option,
    __hash: hash,
  };
}

function getGroupFilters(
  group: Array<FdrFiltersFragment | FdrFlightFiltersFragment>
): TGroupedFilter {
  const grouped: TNormalizedSingleSelectFilter[] = group
    .filter(Boolean)
    .map(f => getSingleFilter(f as FdrSingleSelectFilterFragment));

  const filter = grouped[0];
  const hash = grouped.map(g => g.__hash).join('-');

  return {
    id: filter.id as EFilterIds,
    name: filter.name,
    visible: true,
    type: ENormalizedFilterType.GROUPED,
    grouped,
    __hash: hash,
  };
}

export function normalizeFilters(
  filters: Array<FdrFiltersFragment | FdrFlightFiltersFragment>
): Array<[EFilterIds, TNormalizedFilter]> {
  let rating = null;
  let departureTime = null;
  let departureArrivalTime = null;

  const filtersMap = new Map(
    filters?.filter(f => f.visible).map(f => [f.id, f])
  );

  return (
    filters?.filter(f => f.id === EFilterIds.AIRLINES || f.visible) || []
  ).reduce((acc: Array<[EFilterIds, TNormalizedFilter]>, filter) => {
    const id: EFilterIds = filter.id as EFilterIds;

    if (isRatingFilter(id)) {
      if (rating) return acc;

      rating = getGroupFilters([
        filtersMap.get(EFilterIds.HP_RATINGS),
        filtersMap.get(EFilterIds.TA_RATINGS),
      ]);

      return [...acc, [id, rating]];
    }

    if (isFlightDepartureTimeFilter(id)) {
      if (departureTime) return acc;

      departureTime = getGroupFilters([
        filtersMap.get(EFilterIds.DEPARTURE_FLIGHT_TIME),
        filtersMap.get(EFilterIds.RETURN_FLIGHT_TIME),
      ]);

      return [...acc, [id, departureTime]];
    }

    if (isFlightDepartureArrivalTimeFilter(id)) {
      if (departureArrivalTime) return acc;

      departureArrivalTime = getGroupFilters([
        filtersMap.get(EFilterIds.DEPARTURE_FLIGHT_DEPARTURE_TIME),
        filtersMap.get(EFilterIds.DEPARTURE_FLIGHT_ARRIVAL_TIME),
        filtersMap.get(EFilterIds.RETURN_FLIGHT_DEPARTURE_TIME),
        filtersMap.get(EFilterIds.RETURN_FLIGHT_ARRIVAL_TIME),
      ]);

      return [...acc, [id, departureArrivalTime]];
    }

    if (isSingleSelect(filter)) return [...acc, [id, getSingleFilter(filter)]];

    if (isMultiSelect(filter)) {
      return [...acc, [id, getMultiFilter(filter)]];
    }

    if (isOneOption(filter)) return [...acc, [id, getOneOptionFilter(filter)]];

    return acc;
  }, []);
}

export const isSingleSelect = (
  f
): f is FdrSingleSelectFilterFragment | FdrFlightSingleSelectFilterFragment =>
  [
    'FdrSearchControlsSingleSelectFilter',
    'FdrFlightSearchControlsSingleSelectFilter',
  ].includes(f.__typename);

export const isMultiSelect = (
  f
): f is FdrMultiSelectFilterFragment | FdrFlightMultiSelectFilterFragment =>
  [
    'FdrSearchControlsMultiSelectFilter',
    'FdrFlightSearchControlsMultiSelectFilter',
  ].includes(f.__typename);

export const isOneOption = (
  f
): f is FdrOneOptionFilterFragment | FdrFlightOneOptionFilterFragment =>
  [
    'FdrSearchControlsOneOptionFilter',
    'FdrFlightSearchControlsOneOptionFilter',
  ].includes(f.__typename);

export const isRatingFilter = (id: EFilterIds) => ratingFilters.includes(id);

export const isFlightDepartureTimeFilter = (id: EFilterIds) =>
  departureTimeFilters.includes(id);

export const isFlightDepartureArrivalTimeFilter = (id: EFilterIds) =>
  departureArrivalTimeFilters.includes(id);

const departureArrivalTimeFilters = [
  EFilterIds.DEPARTURE_FLIGHT_DEPARTURE_TIME,
  EFilterIds.DEPARTURE_FLIGHT_ARRIVAL_TIME,
  EFilterIds.RETURN_FLIGHT_DEPARTURE_TIME,
  EFilterIds.RETURN_FLIGHT_ARRIVAL_TIME,
];

const departureTimeFilters = [
  EFilterIds.DEPARTURE_FLIGHT_TIME,
  EFilterIds.RETURN_FLIGHT_TIME,
];

const ratingFilters = [EFilterIds.HP_RATINGS, EFilterIds.TA_RATINGS];
