import { formatDate } from '@hotelplan/libs.utils';
import { FdrFlightType } from '@hotelplan/supergraph-api';
import { FdrFlightPartition } from 'fdr/components/local/fdr-flight-search/fdr-flight-search.types';

const SHORT_DATE_INFO_FORMAT = 'dd.MM.yyyy';

export function prepareDatesInFdrFlightPartitions(
  flightPartitions: Array<FdrFlightPartition> | undefined
): Array<FdrFlightPartition> | undefined {
  if (!flightPartitions) return flightPartitions;

  const firstPartitionDate = flightPartitions[0]?.travelDates?.departureDate;
  const secondPartitionDate = flightPartitions[1]?.travelDates?.departureDate;

  if (
    firstPartitionDate &&
    secondPartitionDate &&
    firstPartitionDate > secondPartitionDate
  ) {
    return flightPartitions.map((partition, index) => {
      return index === 1
        ? { ...partition, travelDates: flightPartitions[0].travelDates }
        : { ...partition };
    });
  }

  return flightPartitions;
}

export function areFdrPartitionsValid(
  flightPartitions: Array<FdrFlightPartition> | null | undefined,
  flightType: FdrFlightType | null | undefined
): boolean {
  const partitionsToCheck =
    flightType === FdrFlightType.OpenJaw
      ? flightPartitions
      : flightPartitions?.slice(0, 1);

  if (!partitionsToCheck) return false;

  // NOTE: Airport fields are mandatory to select.
  return partitionsToCheck.every(
    (partition: FdrFlightPartition) =>
      !!partition.arrivalAirport && !!partition.departureAirport
  );
}

interface IFdrPartitionShortInfo {
  airports: string;
  travelDates: string;
}

export function getFdrPartitionShortInformation(
  flightPartition: FdrFlightPartition,
  isReturnFlight: boolean
): IFdrPartitionShortInfo {
  const returnDate = flightPartition.travelDates?.returnDate;
  const departureDate = flightPartition.travelDates?.departureDate;

  const formattedReturnDate = returnDate
    ? formatDate(returnDate, SHORT_DATE_INFO_FORMAT)
    : '...';
  const formattedDepartureDate = departureDate
    ? formatDate(departureDate, SHORT_DATE_INFO_FORMAT)
    : '...';
  const formattedArrivalAirport = flightPartition.arrivalAirport
    ? `${flightPartition.arrivalAirport.name} (${flightPartition.arrivalAirport.iata})`
    : '...';
  const formattedDepartureAirport = flightPartition.departureAirport
    ? `${flightPartition.departureAirport.name} (${flightPartition.departureAirport.iata})`
    : '...';

  return {
    airports: `${formattedDepartureAirport} - ${formattedArrivalAirport}`,
    travelDates: isReturnFlight
      ? `${formattedDepartureDate} - ${formattedReturnDate}`
      : formattedDepartureDate,
  };
}

export function prepareFdrFlightPartitionsToFormState(
  flightPartitions: FdrFlightPartition[] | null | undefined,
  flightType: FdrFlightType
): FdrFlightPartition[] | null | undefined {
  // NOTE: If it's not an OpenJaw flight type then we need to add initial values to the second flight partitions.
  return flightPartitions && flightType !== FdrFlightType.OpenJaw
    ? [...flightPartitions, { ...flightPartitions[0] }]
    : flightPartitions;
}

export function prepareFdrFlightPartitionsToCriteriaInput(
  flightPartitions: FdrFlightPartition[] | null | undefined,
  flightType: FdrFlightType | null | undefined
): FdrFlightPartition[] | null | undefined {
  // NOTE: If it's not an OpenJaw flight type then we need to remove unnecessary flight partitions.
  return flightPartitions && flightType !== FdrFlightType.OpenJaw
    ? flightPartitions.slice(0, 1)
    : flightPartitions;
}

export function mergeFdrFlightPartitionsWithFGSS(
  flightPartitions: FdrFlightPartition[] | null | undefined,
  globalSearchFlightPartitions: FdrFlightPartition[] | null | undefined
): FdrFlightPartition[] | null | undefined {
  return flightPartitions?.map((partition, i) => {
    let globalSearchPartition = globalSearchFlightPartitions
      ? globalSearchFlightPartitions[i]
      : null;

    // NOTE: If there is no such partition in the FGSS we should try to populate it with the data from the
    // first partition in the FGSS.
    if (!globalSearchPartition && globalSearchFlightPartitions && i > 0) {
      globalSearchPartition = globalSearchFlightPartitions[0];
    }

    return mergeFdrFlightPartitionWithFGSS(partition, globalSearchPartition);
  });
}

export function mergeFdrFlightPartitionWithFGSS(
  partition: FdrFlightPartition,
  globalSearchPartition: FdrFlightPartition | null = null
): FdrFlightPartition {
  if (!globalSearchPartition) return { ...partition };

  const resultTravelDates =
    globalSearchPartition.travelDates || partition.travelDates;

  return {
    ...partition,
    ...globalSearchPartition,
    travelDates: resultTravelDates
      ? {
          ...resultTravelDates,
          defaults: partition.travelDates?.defaults,
        }
      : null,
  };
}
