import {
  adjustFdrTravelDates,
  mapTravelDatesModalToTrackableTravelDates,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import {
  TFdrFlatDestination,
  TPossibleSearchItems,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import {
  IRunSearchData,
  ISearchControlTrackableData,
  ISuggestion,
} from '@hotelplan/libs.tracking';
import { FdrFlightType } from '@hotelplan/supergraph-api';
import { fdrMapTravelTypeToTrackableTravelType } from 'fdr/components/candidate/fdr-search-control/travel-type/fdr-travel-type.mappers';
import { fdrMergeHotelSearchControlState } from 'fdr/components/candidate/fdr-search/mappers/fdr-srl-response-to-state.mapper';
import {
  IFdrProductSrlControlState,
  IFdrSRLState,
} from 'fdr/components/candidate/fdr-search/types/search-form.types';
import {
  EFdrFlightTravelType,
  ELegacySearchControlTravelType,
} from 'fdr/components/candidate/fdr-search/types/travel-type.types';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import {
  IFdrFlightSrlControlState,
  TFdrFlightSrlControlStateWithType,
} from 'fdr/components/local/fdr-flight-search/fdr-flight-search.types';
import {
  mergeFdrFlightPartitionsWithFGSS,
  prepareDatesInFdrFlightPartitions,
  prepareFdrFlightPartitionsToCriteriaInput,
} from 'fdr/components/local/fdr-flight-search/flight-partitions/fdr-flight-partitions.utils';
import { mapFdrFlightTravelDatesModalToTrackableTravelDates } from 'fdr/components/local/fdr-flight-search/flight-travel-dates/fdr-flight-travel-dates.mappers';
import { mapFdrFiltersStateToTrackableData } from './fdr-filter.mapper';

export const mapFlightSearchControlFormStateToTrackableData = (
  state?: IFdrFlightSrlControlState | null
): ISearchControlTrackableData | null | undefined => {
  if (!state) return;

  const flightPartitions = prepareFdrFlightPartitionsToCriteriaInput(
    state.flightPartitions,
    state.flightType
  );

  const travelPeriod = flightPartitions
    ? mapFdrFlightTravelDatesModalToTrackableTravelDates(flightPartitions)
    : undefined;

  const departureAirports =
    flightPartitions?.map(
      ({ departureAirport }) => departureAirport?.iata || ''
    ) || [];
  const returnAirports =
    flightPartitions?.map(({ arrivalAirport }) => arrivalAirport?.iata || '') ||
    [];

  return {
    travelPeriod,
    type: fdrMapTravelTypeToTrackableTravelType(
      state?.extended_travelType.fdr || EFdrFlightTravelType.Flight
    ),
    travellers: state?.travelRooms
      ? {
          adults: state.travelRooms.adults,
          children: state.travelRooms.childrenDobs,
          rooms: state.travelRooms.rooms,
          travellersDistribution: state.travelRooms.travellersDistribution,
        }
      : undefined,
    airports: flightPartitions
      ? {
          departure: departureAirports,
          return: returnAirports,
        }
      : undefined,
  };
};

export const mapSearchControlFormStateToTrackableData = (
  state?: IFdrProductSrlControlState | null
): ISearchControlTrackableData | null | undefined => {
  if (!state) return;

  return {
    type: fdrMapTravelTypeToTrackableTravelType(
      // @ts-ignore @tofix: searchControl
      state?.travelType || ELegacySearchControlTravelType.Package
    ),
    travelPeriod: state?.travelDates
      ? mapTravelDatesModalToTrackableTravelDates(state.travelDates)
      : undefined,
    text: (state?.legacy_travelDestination?.destinations || []).map(
      mapFlatDestinationToTrackableDestination
    ),
    travellers: state?.travelRooms
      ? {
          adults: state.travelRooms.adults,
          children: state.travelRooms.childrenDobs,
          rooms: state.travelRooms.rooms,
          travellersDistribution: state.travelRooms.travellersDistribution,
        }
      : undefined,
  };
};

export const mapSearchControlStateToTrackableData = (
  state?: IFdrSrlControlState | null
): ISearchControlTrackableData | null | undefined => {
  if (!state) return;

  if (state.type === 'HOTEL') {
    return mapSearchControlFormStateToTrackableData(state);
  } else {
    // NOTE: Only for the Flight Search Control.
    return mapFlightSearchControlFormStateToTrackableData(state);
  }
};

export const mapFdrSRLStateToTrackableData = (
  state: IFdrSRLState
): IRunSearchData => {
  return {
    searchControl: mapSearchControlFormStateToTrackableData(
      state.searchControl
    ),
    filters: mapFdrFiltersStateToTrackableData(state.filters),
  };
};

export function mergeFdrFlightSearchControlState(
  searchControlState?: IFdrFlightSrlControlState | null,
  flightGlobalSearchState?: IFdrFlightSrlControlState | null
): TFdrFlightSrlControlStateWithType | null {
  if (!searchControlState) return null;

  let flightPartitions = mergeFdrFlightPartitionsWithFGSS(
    searchControlState.flightPartitions,
    flightGlobalSearchState?.flightPartitions
  );

  const flightType =
    flightGlobalSearchState?.flightType || searchControlState.flightType;

  // reset return date if it is not return type, for proper adjustment
  if (flightPartitions && flightType !== FdrFlightType.Return) {
    flightPartitions.forEach(partition => {
      if (partition.travelDates) {
        partition.travelDates.returnDate = null;
      }
    });
  }

  if (flightPartitions) {
    flightPartitions = flightPartitions.map(partition => ({
      ...partition,
      travelDates: adjustFdrTravelDates(partition.travelDates),
    }));

    flightPartitions = prepareDatesInFdrFlightPartitions(flightPartitions);
  }

  return {
    type: 'FLIGHT',
    ...searchControlState,
    ...flightGlobalSearchState,
    flightPartitions,
  };
}

export function mergeSearchControlState(
  searchControlState?: IFdrSrlControlState | null,
  globalSearchState?: IFdrProductSrlControlState | null,
  flightGlobalSearchState?: IFdrFlightSrlControlState | null
): IFdrSrlControlState | null {
  if (!searchControlState) return null;

  if (searchControlState.type === 'FLIGHT') {
    return mergeFdrFlightSearchControlState(
      searchControlState,
      flightGlobalSearchState
    );
  } else {
    return fdrMergeHotelSearchControlState(
      searchControlState,
      globalSearchState
    );
  }
}

const travelDestinationTypeMapping = new Map<TPossibleSearchItems, string>([
  [`FdrAirport`, 'AIRPORT'],
  [`FdrTheme`, 'THEME'],
  [`FdrHotel`, 'HOTEL'],
  [`FdrCruise`, 'CRUISE'],
  [`FdrRoundTrip`, 'ROUNDTRIP'],
  [`FdrDestination`, 'DESTINATION'],
  [`FdrCountry`, 'COUNTRY'],
  [`FdrContinent`, 'CONTINENT'],
  [`FdrCountryGroup`, 'COUNTRY_GROUP'],
  [`FdrRegion`, 'REGION'],
  [`FdrResort`, 'RESORT'],
]);

export const mapFlatDestinationToTrackableDestination = (
  dest: TFdrFlatDestination
): ISuggestion => {
  return {
    id: dest.id,
    label: dest.name,
    type: travelDestinationTypeMapping.get(dest.__typename),
    mythosCode: dest.mythosCode,
  };
};
