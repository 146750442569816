import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { TravelType as TrackingTravelType } from '@hotelplan/libs.tracking/dist/trackingEvents/trackTravelType';
import { FdrProductTravelType } from '@hotelplan/supergraph-api';
import { FdrTravelTypeData } from 'fdr/components/candidate/fdr-search-control/fdr-search-control.types';
import {
  EFdrFlightTravelType,
  T_Custom_TabsTravelTypesUnit,
  TFdrAdditionTabTravelType,
} from 'fdr/components/candidate/fdr-search/types/travel-type.types';

const fdrTravelTypesMap: FdrTravelTypeData = {
  [FdrProductTravelType.Package]: {
    extended_type: {
      fdr: FdrProductTravelType.Package,
    },
    label: 'common:package.plus',
    icon: 'flughotel',
    testId: 'travelTypesPackage',
  },
  [FdrProductTravelType.HotelOnly]: {
    extended_type: {
      fdr: FdrProductTravelType.HotelOnly,
    },
    label: 'common:hotel',
    icon: 'hotel',
    testId: 'travelTypesHotel',
  },
  [TFdrAdditionTabTravelType.Apartment]: {
    extended_type: null,
    link: {
      de: '/ferienwohnungen' as const,
      fr: '/appartments' as const,
    },
    label: 'common:apartments',
    icon: 'appartments',
    testId: 'travelTypesApartment',
  },
  // @tofix: support searchParam for Flights
  [EFdrFlightTravelType.Flight]: {
    // type: ELegacySearchControlTravelType.Flight,
    extended_type: {
      fdr: EFdrFlightTravelType.Flight,
    },
    label: 'common:flight',
    icon: 'flug',
    testId: 'travelTypesFlight',
  },
  [TFdrAdditionTabTravelType.Cruise]: {
    extended_type: null,
    label: 'common:search.control.cruise',
    link: {
      de: 'https://kreuzfahrten.hotelplan.ch/home',
      fr: 'https://croisieres.hotelplan.ch/home',
    },
    isExternal: true,
    icon: 'cruise',
    testId: 'travelTypesCruise',
  },
  [TFdrAdditionTabTravelType.Roundtrip]: {
    extended_type: null,
    link: {
      de: '/ferien/rundreisen/t-4' as const,
      fr: '/vacances/circuits/t-4' as const,
    },
    label: 'common:roundtrip_other' as const,
    icon: 'rundreisen',
    testId: 'travelTypesRoundtrip',
  },
};

const fdrTravelTypesMapB2B: Pick<
  FdrTravelTypeData,
  TFdrAdditionTabTravelType.Apartment
> = {
  [TFdrAdditionTabTravelType.Apartment]: {
    extended_type: null,
    link: {
      de: 'https://www.interhome.group/de-ch/partnerprogramm' as const,
      fr: 'https://www.interhome.group/fr-ch/programme-partenaire' as const,
    },
    label: 'common:apartments',
    icon: 'appartments',
    testId: 'travelTypesApartment',
  },
};

export const fdrMapTravelTypeToTravelTypeData = (
  travelType: T_Custom_TabsTravelTypesUnit,
  channelType?: AuthChannelType
) => {
  if (channelType == AuthChannelType.B2B && fdrTravelTypesMapB2B[travelType]) {
    return fdrTravelTypesMapB2B[travelType];
  }

  return fdrTravelTypesMap[travelType];
};

const fdrTravelTypeToTrackingType: {
  [K in T_Custom_TabsTravelTypesUnit]: TrackingTravelType;
} = {
  [TFdrAdditionTabTravelType.Apartment]: 'apartments',
  [TFdrAdditionTabTravelType.Cruise]: 'cruise',
  [EFdrFlightTravelType.Flight]: 'flight',
  [FdrProductTravelType.HotelOnly]: 'hotel',
  [FdrProductTravelType.Package]: 'package',
  [TFdrAdditionTabTravelType.Roundtrip]: 'roundtrip',
};

export const fdrMapTravelTypeToTrackableTravelType = (
  travelType: T_Custom_TabsTravelTypesUnit
) => {
  return fdrTravelTypeToTrackingType[travelType];
};
